import { Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    ignoreElements,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
    routePaths,
    TenantParams,
} from '../../../ui/components/routes/RouteList';
import { ErrorNormalized } from '../../../models/error.models';
import { getRouteMatch } from '../../helpers/epics/location-epic.helper';
import { RootEpic } from '../epic.root-index';
import { configurationInit } from '../initialization/initialization.actions';
import * as actions from './authentication.actions';
import { parse } from 'qs';

const handleAuthError = <T>(obs: Observable<T>) => {
    return obs.pipe(
        catchError(error => {
            // todo? maybe make this better? yes make better.
            const normalized: ErrorNormalized = {
                status: -1,
                statusText: 'Unknown',
                message: `Authentication Error: ${error}`,
            };
            return of(actions.authenticationFailure({ error: normalized }));
        })
    );
};

export const authenticationInitEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(configurationInit)),
        mergeMap(action => of(actions.authenticationInit(action.payload.auth)))
    );
};

export const authenticationLoginEpic: RootEpic = (
    action$,
    state$,
    services
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationInit)),
        map(action => services.auth.init(action.payload)),
        mergeMap(() => services.auth.clearStaleState()),
        mergeMap(() => services.auth.getUser()),
        mergeMap(user => of(actions.authenticationSetUser({ user }))),
        handleAuthError
    );
};

export const authenticationValidateUserEpic: RootEpic = (
    action$,
    state$,
    services
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationSetUser)),
        filter(action => action.payload.user !== null),
        mergeMap(action => {
            const user = action.payload.user!;

            if (user.expired) {
                return services.auth
                    .removeUser()
                    .pipe(
                        switchMap(() => of(actions.authenticationRedirect()))
                    );
            }

            return of(actions.authenticationComplete({ user }));
        }),
        handleAuthError
    );
};

export const authenticationNullUserEpic: RootEpic = (
    action$,
    state$,
    services
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationSetUser)),
        filter(action => action.payload.user === null),
        mergeMap(() => {
            const { hash } = state$.value.router.location;

            const idTokenExists = !!parse(hash).id_token;
            if (idTokenExists) {
                return services.auth
                    .signinRedirectCallback()
                    .pipe(
                        switchMap(user =>
                            of(actions.authenticationComplete({ user }))
                        )
                    );
            }

            return of(actions.authenticationRedirect());
        }),
        handleAuthError
    );
};

export const authenticationRedirectEpic: RootEpic = (
    action$,
    state$,
    services
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationRedirect)),
        map(
            () =>
                getRouteMatch<TenantParams>(
                    state$.value.router.location,
                    routePaths.tenant
                )?.params.tenant
        ),
        switchMap(tenant =>
            services.auth.signinRedirect(tenant).pipe(ignoreElements())
        ),
        handleAuthError
    );
};

export const authenticationLogoutEpic: RootEpic = (
    action$,
    state$,
    services
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationLogout)),
        mergeMap(() => services.auth.signoutRedirect()),
        ignoreElements()
    );
};
